import React from "react";
import Layout from "../components/layout";
import SearchEngineOptimization from "../components/SearchEngineOptimization";

const NotFoundPage = ({ data }) => {
	const browser = typeof window !== "undefined" && window;

	return (
		<Layout>
			{browser && (
				<>
					<SearchEngineOptimization title="404: Not found" />
					<section className="section section--is-spacious">
						<div className="container container--is-extra-narrow">
							<div className="text-center">
								<div className="markdown">
									<img
										src="https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy.gif"
										class="embedly-card"
										data-card-width="100%"
										data-card-controls="0"
										alt=""
									/>
									<p>
										<h1>You've done it!!</h1>
									</p>
									<p>
										<strong>You have defeated the internet </strong>
									</p>
									<p>
										Now all there's left to do is click is unsuspicious link...
									</p>
									<a class="btn btn--black-green" href="/">
										Click me human
									</a>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</Layout>
	);
};

export default NotFoundPage;
